import * as React from "react";
import Navbar from "../components/nav-bar";
import Footer from "../components/footer";
import Banner from "../components/banner";
import { Row, Container, Col } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby";
const Blog = () => {
  const { allStrapiBlogs } = useStaticQuery(pageQuery);
  const { edges } = allStrapiBlogs;
  return (
    <div className="outer-container">
      <Navbar></Navbar>
      <Container fluid className="p-0 overflow-hidden">
        <Banner title="Blog Articles" backgroundClass={null}></Banner>
        <Row className="article p-5">
          <Col lg={12} md={12} sm={12}>
            <Row>
              { edges && edges.map ( blog => (
              <Col lg={3} md={12} sm={12} className="blog-col" key={blog.node.strapiId}>
                <Link to={'/blogs/' + blog.node.url}>
                    { blog.node.BlogImage.localFile.publicURL ?
                        (  
                          <div className="blog-entry" style = {{ 
                              background: `linear-gradient(0deg, rgba(33, 121, 191, 0.75), rgba(33, 121, 191, 0.75)), url(${blog.node.BlogImage.localFile.publicURL})`,
                              backgroundPosition: `center`,
                              backgroundSize: `cover`
                            }}>
                          <div className="title">{blog.node.Title}</div>
                            <h3>{blog.node.ShortDescription}</h3>
                          </div>
                        ) :
                        (  
                          <div className="blog-entry">
                          <div className="title">{blog.node.Title}</div>
                            <h3>{blog.node.ShortDescription}</h3>
                          </div>
                        )
                    }
                </Link>
              </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Footer></Footer>
      </Container>
    </div>
  );
};

export default Blog;

export const pageQuery = graphql` 
  query BlogsPageQuery {
    allStrapiBlogs {
      edges {
        node {
          strapiId
          Content
          Title
          BlogImage {
            localFile {
              publicURL
            }
          }
          ShortDescription
          url
        }
      }
    }
  }`
;

